video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.prijzen {
  /* background: url('/images/jan-huber-3Btf2cTAGKs-unsplash.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  object-fit: contain;
}

.prijzen > h1 {
  color: white;
  font-size: 70px;
  margin-bottom: 32px;
}

.a {
  color: white;
  text-decoration: bold none;
}

.a:hover {
  color: white;
  text-decoration: none;
}

.prijzen > p {
  margin-top: 8px;
  color: #fff;
  font-size: 28px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .prijzen > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .prijzen > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .prijzen > p {
    font-size: 25px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .prijzen {
    /* background: url('/images/jan-huber-3Btf2cTAGKs-unsplash.jpg') center center/cover no-repeat; */
    height: 30rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
    object-fit: contain;
  }
}
